import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Container from "./Container";

const SectionHeader = ({ children }) => {
  return <h2 className="text-3xl font-bold">{children}</h2>;
};

const PdbtBenefits = () => {
  return (
    <div className="bg-gray-100 pt-16 self-stretch">
      <Container column>
        <SectionHeader>
          ¡Descubre cómo tu negocio puede ahorrar y crecer con energía solar!
        </SectionHeader>
        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 justify-center items-center rounded-3xl my-6 p-4 max-w-5xl">
            <div className="flex flex-col shadow-md rounded-md bg-white h-full p-5">
              <div className="pb-5">
                <StaticImage
                  alt="icono recibo de luz congelado"
                  height={100}
                  src="../../images/illustrations/frozen-bill-icon.png"
                />
              </div>
              <div className="font-bold">Fija tu tarifa de energía</div>
              <div>Despídete de los incrementos anuales de CFE</div>
            </div>
            <div className="flex flex-col shadow-md rounded-md bg-white h-full p-5">
              <div className="pb-5">
                <StaticImage
                  alt="icono hombre sentado sillon"
                  height={100}
                  src="../../images/illustrations/comfort-icon.png"
                />
              </div>
              <div className="font-bold">Incrementa tus utilidades</div>
              <div>Reduciendo tus costos operativos</div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default PdbtBenefits;
