import React, { useCallback } from "react";
import { capture } from "../common/analytics";
import { LinkButton, FeatureGridWithOffsetIcons } from ".";
import Container from "./Container";
import { useSearchParams } from "./useSearchParams";
import {
  PencilIcon,
  ShieldCheckIcon,
  ComputerDesktopIcon,
  DocumentCheckIcon,
  LockClosedIcon,
  CogIcon,
} from "@heroicons/react/24/outline";
import { Feature } from "./theme/FeatureGridWithOffsetIcons";

const SectionHeader = ({ children }) => {
  return <h2 className="text-3xl font-bold">{children}</h2>;
};

const pdbtbenefits: Feature[] = [
  {
    name: "Productos Personalizados",
    icon: PencilIcon,
    description: "",
  },
  {
    name: "Garantía de Producción",
    icon: ShieldCheckIcon,
    description: "",
  },
  {
    name: "Servicio de Consultoría Remoto y Rápido",
    icon: ComputerDesktopIcon,
    description: "",
  },
  {
    name: "Beneficios Fiscales",
    icon: DocumentCheckIcon,
    description: "",
  },
  {
    name: "Póliza de seguro por $1,000,000 para el inmueble",
    icon: LockClosedIcon,
    description: "",
  },
  {
    name: "Mantenimiento Correctivo y Preventivo",
    icon: CogIcon,
    description: "",
  },
];

type LeaseVsBuyProps = {
  canReserveOnline: boolean;
};

const LeaseVsBuy = ({ canReserveOnline }: LeaseVsBuyProps) => {
  const [{}, setSp] = useSearchParams();

  const openInstallationModal = useCallback(() => {
    capture("reserve_online_installation_modal_open");
    setSp({ installModal: "open" });
  }, [setSp]);
  return (
    <div className="bg-gray-100 py-16 self-stretch">
      <Container column>
        <SectionHeader>
          ¿Lo mejor? ¡Ofrecemos esquema de renta con beneficios adicionales!
        </SectionHeader>
        <div className="">
          {/* Desktop Version */}
          <div className="hidden md:block overflow-x-auto py-5 px-10">
            <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-gray-200 text-gray-600 text-lg font-bold">
                  <th className="py-3 px-6 text-left"></th>
                  <th className="py-3 px-6 text-left bg-green-600 text-white">
                    Rentar
                  </th>
                  <th className="py-3 px-6 text-left">Comprar</th>
                </tr>
              </thead>
              <tbody className="text-gray-600 text-md">
                <tr className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left font-bold">
                    Inversión Inicial
                  </td>
                  <td className="py-3 px-6 text-left bg-green-50 text-gray-900">
                    Baja o $0
                  </td>
                  <td className="py-3 px-6 text-left">
                    Alta (inversión completa en el sistema)
                  </td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left font-bold">Riesgo</td>
                  <td className="py-3 px-6 text-left  bg-green-50 text-gray-900">
                    Ninguno: Bright se encarga de todo
                  </td>
                  <td className="py-3 px-6 text-left">
                    Tienes que encargarte de todas las implicaciones
                  </td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left font-bold">
                    Mantenimiento y Operación
                  </td>
                  <td className="py-3 px-6 text-left  bg-green-50 text-gray-900">
                    Incluido en el contrato
                  </td>
                  <td className="py-3 px-6 text-left">
                    Tu responsabilidad: requiere inversión adicional para su
                    correcto funcionamiento.
                  </td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left font-bold">
                    Impacto en Flujo de Caja
                  </td>
                  <td className="py-3 px-6 text-left  bg-green-50 text-gray-900">
                    Pagos mensuales bajos y predecibles
                  </td>
                  <td className="py-3 px-6 text-left">
                    Mayor impacto inicial; necesitas esperar al ROI para empezar
                    a obtener ahorros.
                  </td>
                </tr>
                <tr className="border-b border-gray-200 hover:bg-gray-100">
                  <td className="py-3 px-6 text-left font-bold">
                    Beneficios Fiscales
                  </td>
                  <td className="py-3 px-6 text-left  bg-green-50 text-gray-900">
                    Deducible como gasto operativo
                  </td>
                  <td className="py-3 px-6 text-left">
                    Depreciación acelerada y créditos fiscales, pero se vuelve
                    un gasto para tu negocio.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          {/* Mobile Version */}
          <div className="block md:hidden mt-8 space-y-4 py-4">
            <div className="bg-white shadow-md rounded-lg p-4">
              <h3 className="font-bold text-lg">Inversión Inicial</h3>
              <p className="text-green-600">
                <span className="font-semibold ">Rentar:</span> Baja o $0
              </p>
              <p>
                <span className="font-semibold">Comprar:</span> Alta (inversión
                completa en el sistema)
              </p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4">
              <h3 className="font-bold text-lg">Riesgo</h3>
              <p className="text-green-600">
                <span className="font-semibold">Rentar:</span> Ninguno, Bright
                se encarga de todo
              </p>
              <p>
                <span className="font-semibold">Comprar:</span>Tienes que
                encargarte de todas las implicaciones
              </p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4">
              <h3 className="font-bold text-lg">Mantenimiento y Operación</h3>
              <p className="text-green-600">
                <span className="font-semibold">Rentar:</span> Incluido en el
                contrato
              </p>
              <p>
                <span className="font-semibold">Comprar:</span>
                Tu responsabilidad: requiere inversión adicional para su
                correcto funcionamiento.
              </p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4">
              <h3 className="font-bold text-lg">Impacto en Flujo de Caja</h3>
              <p className="text-green-600">
                <span className="font-semibold">Rentar:</span> Pagos mensuales
                bajos y predecibles
              </p>
              <p>
                <span className="font-semibold">Comprar:</span>
                Mayor impacto inicial; necesitas esperar al ROI para empezar a
                obtener ahorros.
              </p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4">
              <h3 className="font-bold text-lg">Beneficios Fiscales</h3>
              <p className="text-green-600">
                <span className="font-semibold">Rentar:</span> Deducible como
                gasto operativo
              </p>
              <p>
                <span className="font-semibold">Comprar:</span>
                Depreciación acelerada y créditos fiscales, pero se vuelve un
                gasto para tu negocio.
              </p>
            </div>
          </div>
          <p className="text-lg">
            ¿Quieres una propuesta personalizada? ¡Solo necesitamos un recibo de
            luz!
          </p>
          {canReserveOnline ? (
            <div className="flex flex-col mx-auto md:flex-row mt-8 justify-center  space-y-6 md:space-y-0 md:space-x-6 py-4">
              <button
                onClick={openInstallationModal}
                className="flex justify-center items-center font-medium rounded-md transform transition duration-200 h-12 text-lg px-6 cursor-pointer text-white bg-blue-500 hover:bg-blue-400 shadow-md hover:shadow-lg hover:scale-101"
              >
                Reservar mi instalación
              </button>
            </div>
          ) : (
            <div className="flex flex-col mx-auto md:flex-row mt-8 justify-center  space-y-6 md:space-y-0 md:space-x-6 py-4">
              <LinkButton
                shadow
                arrow
                size="large"
                to="https://api.whatsapp.com/send?phone=5215541642354&text=%C2%A1Hola!%20Vi%20una%20propuesta%20interactiva%20y%20deseo%20cotizar."
              >
                Solicita una cotización personalizada
              </LinkButton>
            </div>
          )}
          <FeatureGridWithOffsetIcons
            description="Nuestro servicio premium incluye:"
            features={pdbtbenefits}
          />
        </div>
      </Container>
    </div>
  );
};

export default LeaseVsBuy;
