import React from "react";
import Container from "./Container";
import PdbtBenefits from "./PdbtBenefits";

const SectionHeader = ({ children }) => {
  return <h2 className="text-3xl font-bold">{children}</h2>;
};

const SectionDescription = ({ children }) => {
  return <p className="text-lg font-base mt-4 text-gray-500">{children}</p>;
};

const PdbtIntro = () => {
  return (
    <div className="bg-gray-100 py-16 self-stretch">
      <Container column>
        <SectionHeader>
          ¿Sabías que podrías estar pagando más de lo necesario por tu
          electricidad?
        </SectionHeader>
        <SectionDescription>
          La tarifa PDBT es un esquema diseñado para PyMEs, no tiene subsidio
          gubernamental y el costo por kilowatt es el segundo más alto después
          de la tarifa DAC.{" "}
          <b>
            Estos costos energéticos seguramente impactan de manera negatica tus
            márgenes de ganancia.
          </b>
        </SectionDescription>
      </Container>
      <PdbtBenefits />
    </div>
  );
};

export default PdbtIntro;
