import React, { useCallback, useEffect, useState } from "react";
import classnames from "classnames";
import {
  Button,
  Layout,
  SEO,
  FeatureGridWithOffsetIcons,
  LinkButton,
} from "../components";
import {
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  LockClosedIcon,
  ComputerDesktopIcon,
  CogIcon,
  ShieldCheckIcon,
  CurrencyDollarIcon,
  PencilIcon,
} from "@heroicons/react/24/outline";
import PricerForm, {
  PricerFormValues,
  customerType,
} from "../components/PricerForm";
import { ArrowRightIcon } from "@heroicons/react/20/solid";
import { useSeoProps } from "../components/useSeoProps";
import Testimonials from "../components/Testimonials";
import Container from "../components/Container";
import { useSearchParams } from "../components/useSearchParams";
import Modal from "../components/Modal";

import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import {
  WebleadPreviewResponse,
  apiRequestToOps,
  webleadPreview,
} from "../common/api";
import { Loading } from "../components/Loading";
import {
  capture,
  interactiveProposalGroupVariants,
  useBrightFeatureFlag,
} from "../common/analytics";
import { upperFirst } from "lodash";
import { useUiFriendlyWebleadPreviewParams } from "../components/pricerFormUtils";
import ScheduleInstallationForm from "../components/ScheduleInstallationForm";
import { ScrollToTop } from "../misc/utils";
import { graphql } from "gatsby";
import LeaseVsBuy from "../components/LeaseVsBuy";
import WelcomeComfort from "../components/WelcomeComfort";
import PdbtIntro from "../components/PdbtIntro";
import PdbtTestimonials from "../components/PdbtTestimonial";

/**
 * Determine if react is currently loading.  If this returns true, it means that
 * react hasn't yet rehydrated the page yet, so you are still seeing the server
 * generated HTML.
 */
export function useReactLoading(): boolean {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, [setLoading]);

  return loading;
}

const SectionHeader = ({ children }) => {
  return <h2 className="text-3xl font-bold">{children}</h2>;
};

const SectionDescription = ({ children }) => {
  return <p className="text-lg font-base mt-4 text-gray-500">{children}</p>;
};

const DacIsBadSection = ({ t1Price, dacPrice }) => {
  return (
    <div className="bg-gray-100 py-16 self-stretch">
      <Container column>
        <SectionHeader>
          ¿Sabías que te encuentras en tarifa subsidiada?
        </SectionHeader>
        <SectionDescription>
          Por el momento cuentas con apoyo del gobierno en tu recibo de luz. El
          cual podrías perder si continúas con un consumo alto de electricidad:
        </SectionDescription>
        <div className="grid grid-cols-3 self-center justify-stretch items-center rounded-3xl bg-white my-6 p-12 max-w-2xl">
          <div className="flex flex-col">
            <div>Tu recibo de luz hoy:</div>
            <div className="font-bold">${t1Price}</div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <ArrowRightIcon className="h-6 w-6" />
          </div>
          <div className="flex flex-col">
            <div>Tu recibo de luz si pierdes el subsidio:</div>
            <div className="font-bold text-red-600">${dacPrice}</div>
          </div>
        </div>
        <SectionDescription>
          Esto es debido a que existe la <b>tarifa DAC</b> (Doméstica de Alto
          Consumo), que se aplica cuando has tenido un alto consumo eléctrico en
          los últimos 6 bimestres.
          <br /> <br />
          <b>
            Importante: Si llegas a esta tarifa, no recibirás ningún aviso,
            simplemente tu recibo de luz llegará mucho más alto de lo normal.
          </b>
        </SectionDescription>
      </Container>
    </div>
  );
};

const YouAreInDacSection = () => {
  return (
    <div className="bg-gray-100 py-16 self-stretch">
      <Container column>
        <SectionHeader>
          ¿Sabías que ya perdiste el subsidio de CFE?
        </SectionHeader>
        <SectionDescription>
          Basándonos en cuanto pagas de luz actualmente, parece ser que{" "}
          <b>has perdido el apoyo gubernamental </b>y ahora te encuentras en la
          tarifa más cara de CFE.
          <br />
          <br />
          Esto es debido a que existe la <b>tarifa DAC</b> (Doméstica de Alto
          Consumo), la cual se aplica a todos aquellos hogares que han tenido un
          alto consumo eléctrico durante los últimos 6 bimestres, perdiendo así
          el subsidio y apoyo gubernamental.
          <br />
          <br />
          De todas las tarifas que tiene CFE en residencia, negocios, industria,
          etc, la tarifa DAC es la más cara. El costo por kWh ronda entre los
          $6, comparado con el $1 por kWh con las tarifas subsidiadas.
        </SectionDescription>
      </Container>
    </div>
  );
};

const ApplianceUsageWarning = ({
  label,
  hours,
}: {
  label: string;
  hours: number;
}) => {
  const rounded = hours.toFixed(1);
  let timeLabel = "horas";
  let timeValue = rounded;
  if (rounded === "1") {
    timeLabel = "hora";
  } else if (hours < 1) {
    timeLabel = "minutos";
    timeValue = (hours * 60).toFixed(0);
  }

  return (
    <div className="flex flex-col shadow-md rounded-md p-5">
      <div className="">{label}</div>
      <div className="font-bold">{timeValue}</div>
      <div className="">{timeLabel} más al día</div>
    </div>
  );
};

const ConsumptionsToBeDac = ({
  response,
  annualDacPrice,
}: {
  response: WebleadPreviewResponse;
  annualDacPrice: number;
}) => {
  const kwhUntilDac = parseFloat(response.t1ResidentialInfo.kwhUntilDac);

  /*
    These calculations are based on these assumptions:
    Air Fryer: 1040 Watts
    TV: 800 Watts
    Computer: 500 Watts (this is probably not a laptop I guess)
    Minisplit: 2000 Watts
  */

  const wattHoursPerDayUntilDac = (kwhUntilDac / 365) * 1000;
  const airfryerHours = wattHoursPerDayUntilDac / 1040;
  const tvHours = wattHoursPerDayUntilDac / 800;
  const computerHours = wattHoursPerDayUntilDac / 500;
  const minisplitHours = wattHoursPerDayUntilDac / 2000;

  return (
    <div className="bg-white py-16 self-stretch">
      <Container column>
        <SectionHeader>Podrías llegar a esta tarifa pronto</SectionHeader>
        <SectionDescription>
          En tu caso, podrías alcanzar la tarifa DAC si sucede alguno de los
          siguientes casos:
        </SectionDescription>

        <div className="flex justify-center items-center">
          <div className="grid grid-cols-1 gap-10 md:grid-cols-2 justify-center items-center rounded-3xl bg-white my-6 p-4">
            <ApplianceUsageWarning
              label="Cocinar con una freidora de aire por"
              hours={airfryerHours}
            />
            <ApplianceUsageWarning
              label="Usar tu televisión por"
              hours={tvHours}
            />
            <ApplianceUsageWarning
              label="Navegar en tu computadora por"
              hours={computerHours}
            />
            <ApplianceUsageWarning
              label="Utilizar tu minisplit por"
              hours={minisplitHours}
            />
          </div>
        </div>

        <SectionDescription>
          Además, salir de esta tarifa puede tomarte más de un año,{" "}
          <b>
            llegando a gastar hasta ${annualDacPrice.toFixed(0)} en tus recibos
            de luz.
          </b>
        </SectionDescription>
      </Container>
    </div>
  );
};

const HowToGetOutofDac = ({ annualDacPrice }) => {
  return (
    <div className="bg-white py-16 self-stretch">
      <Container column>
        <SectionHeader>¿Cómo salir de DAC?</SectionHeader>
        <SectionDescription>
          Salir de esta tarifa puede ser muy complicado, ya que tendrías que
          limitar tus consumos eléctricos por varios meses hasta regresar a una
          tarifa subsidiada.{" "}
          <b>
            Podrías llegar a gastar hasta ${annualDacPrice.toFixed(0)} en
            electricidad antes de lograrlo.
          </b>
          <br />
          <br />
          Este proceso puede ser muy costoso y complicado, además no te asegura
          que no regreses a la tarifa DAC.
        </SectionDescription>
      </Container>
    </div>
  );
};

const TabButton = ({ label, selected, onSelect }) => {
  return (
    <button
      type="button"
      onClick={onSelect}
      className={classnames(
        selected
          ? "bg-white text-gray-800"
          : "text-gray-600 hover:text-gray-800",
        "rounded-t-lg px-3 py-2 text-sm font-medium",
      )}
    >
      {label}
    </button>
  );
};

type SubscriptionPricingProps = {
  response: WebleadPreviewResponse;
  canReserveOnline: boolean;
};

const SubscriptionPricing = ({
  response,
  canReserveOnline,
}: SubscriptionPricingProps) => {
  let { monthlyFee } = response.operating;
  monthlyFee = parseFloat(monthlyFee).toFixed(0);
  const minMonthlyFee = (parseFloat(monthlyFee) * 0.9).toFixed(0);
  const maxConsumptionIncrease = response.noCostConsumptionIncreasePercentMax;

  let consumptionIncreaseTxt = `${maxConsumptionIncrease}%`;

  const [{}, setSp] = useSearchParams();

  const openInstallationModal = useCallback(() => {
    capture("reserve_online_installation_modal_open");
    setSp({ installModal: "open" });
  }, [setSp]);

  return (
    <div>
      <div className="p-5">
        <StaticImage
          alt="icono suscripción"
          height={100}
          src="../images/illustrations/subscription-icon.png"
        />
      </div>
      <SectionHeader>Suscripción</SectionHeader>
      <SectionDescription>
        Renta tu sistema fotovoltaico sin tener que invertir en él. Reduce tu
        recibo de luz hasta un 98% y solo paga una mensualidad por la energía
        que usas.
      </SectionDescription>
      <div>
        <div className="flex justify-center w-full">
          <div className="flex my-6 p-6 flex-col shadow-md rounded-md bg-white h-full w-full md:w-1/4">
            <div className="py-2 text-center">Utiliza hasta un</div>
            <div className="font-bold text-xl text-center">
              {consumptionIncreaseTxt}
            </div>
            <div className="pt-2 text-center">
              más electricidad en verano sin aumentar tu pago
            </div>
          </div>
        </div>
        {/* disabling this to stop hurting conversation due the recent changes in prices
        <div className="flex justify-center items-center items-stretch gap-x-4">
          <div className="flex my-6 p-6 flex-col shadow-md rounded-md bg-white h-full w-1/2 md:w-auto">
            <div className="">Pagarías una mensualidad de</div>
            <div className="font-bold text-xl">
              ${minMonthlyFee} - ${monthlyFee}
            </div>
          </div>
          <div className="flex my-6 p-6 flex-col shadow-md rounded-md bg-white h-full w-1/2 md:w-auto">
            <div className="">Con un escalador anual de</div>
            <div className="font-bold text-xl">4.5%</div>
          </div>
        </div> */}
        {canReserveOnline ? (
          <div className="flex flex-col mx-auto md:flex-row mt-8 justify-center  space-y-6 md:space-y-0 md:space-x-6">
            <button
              onClick={openInstallationModal}
              className="flex justify-center items-center font-medium rounded-md transform transition duration-200 h-12 text-lg px-6 cursor-pointer text-white bg-blue-500 hover:bg-blue-400 shadow-md hover:shadow-lg hover:scale-101"
            >
              Reservar mi instalación
            </button>
          </div>
        ) : (
          <div className="flex flex-col mx-auto md:flex-row mt-8 justify-center  space-y-6 md:space-y-0 md:space-x-6">
            <LinkButton
              shadow
              arrow
              size="large"
              to="https://api.whatsapp.com/send?phone=5215541642354&text=%C2%A1Hola!%20Vi%20una%20propuesta%20interactiva%20y%20deseo%20cotizar."
            >
              Solicita una cotización personalizada
            </LinkButton>
          </div>
        )}
      </div>
      <FeatureGridWithOffsetIcons
        description="Nuestro servicio premium incluye:"
        features={benefits}
      />
    </div>
  );
};

const FinancialPricing = ({
  response,
}: {
  response: WebleadPreviewResponse;
}) => {
  const { totalPrice, totalSavings, totalSavingsPercent } = response.financial;
  const approxSavingsPercent = parseFloat(totalSavingsPercent).toFixed(0);
  return (
    <div>
      <div className="p-5">
        <StaticImage
          alt="icono pago de contado"
          height={100}
          src="../images/illustrations/upfront-icon.png"
        />
      </div>
      <SectionHeader>Pago de Contado</SectionHeader>
      <SectionDescription>
        Adquiere tus paneles solares en dos exhibiciones: 70% antes de la
        instalación y 30% después.
        <br />
        <br />
        Además, te regalamos 1 año de nuestro servicio premium.
        <br />
        <br />
        ¿Quieres una propuesta personalizada? ¡Solo necesitamos un recibo de
        luz!
      </SectionDescription>
      <div className="flex flex-col mx-auto md:flex-row mt-8 justify-center  space-y-6 md:space-y-0 md:space-x-6">
        <LinkButton
          shadow
          arrow
          size="large"
          to="https://api.whatsapp.com/send?phone=5215541642354&text=%C2%A1Hola!%20Vi%20una%20propuesta%20interactiva%20y%20deseo%20cotizar."
        >
          Solicita una cotización personalizada
        </LinkButton>
      </div>
      <div className="flex justify-center items-center">
        <div className="grid grid-cols-1 gap-10 md:grid-cols-3 justify-center items-center rounded-3xl my-6 p-4 max-w-5xl">
          <div className="flex flex-col shadow-md rounded-md bg-white h-full p-5">
            <div className="font-bold">Costo total</div>
            <div>${totalPrice}</div>
          </div>
          <div className="flex flex-col shadow-md rounded-md bg-white h-full p-5">
            <div className="font-bold">Ahorros por 25 años</div>
            <div>${totalSavings}</div>
          </div>
          <div className="flex flex-col shadow-md rounded-md bg-white h-full p-5">
            <div className="font-bold">Porcentaje de ahorro</div>
            <div>~{approxSavingsPercent}%</div>
          </div>
        </div>
      </div>
    </div>
  );
};

type FinancingSectionProps = {
  response: WebleadPreviewResponse;
  canReserveOnline: boolean;
};

const FinancingSection = ({
  response,
  canReserveOnline,
}: FinancingSectionProps) => {
  const [tab, setTab] = useState<"subscription" | "financial">("subscription");

  const onSelectSubscription = useCallback(() => {
    setTab("subscription");
  }, [setTab]);

  const onSelectFinancial = useCallback(() => {
    setTab("financial");
  }, [setTab]);

  return (
    <div className="bg-gray-100 py-16 self-stretch">
      <Container column>
        <SectionHeader>
          ¿Lo mejor? ¡No tienes que hacer inversión inicial!
        </SectionHeader>
        <div className="flex mt-8 flex-row gap-x-4 justify-center z-10">
          <TabButton
            label="Suscripción"
            onSelect={onSelectSubscription}
            selected={tab === "subscription"}
          />
          <TabButton
            label="Pago de Contado"
            onSelect={onSelectFinancial}
            selected={tab === "financial"}
          />
        </div>
        <div className="p-4 bg-white shadow-lg rounded-lg">
          {tab === "subscription" && (
            <SubscriptionPricing
              response={response}
              canReserveOnline={canReserveOnline}
            />
          )}
          {tab === "financial" && <FinancialPricing response={response} />}
        </div>
      </Container>
    </div>
  );
};
const benefits = [
  {
    name: "Cero Inversión Inicial",
    description:
      "Por tan sólo $0 mxn*, puedes empezar a disfrutar de energía solar bajo un esquema de renta mensual.",
    disclosure: "*Aplican restricciones. Consulta con tu Energy Expert.",
    icon: CurrencyDollarIcon,
  },
  {
    name: "Diseño Personalizado",
    description:
      "Elegimos el tamaño y la ubicación de tu sistema fotovoltaico de acuerdo a tus necesidades energéticas, la cantidad de sol y el tamaño de tu techo.",
    icon: PencilIcon,
  },
  {
    name: "Garantía",
    description:
      "Tenemos la garantía de producción más alta del mercado: Si tu sistema no produce lo acordado, te reembolsamos.",
    icon: ShieldCheckIcon,
  },
  {
    name: "Monitoreo",
    description:
      "Supervisamos 24/7 que el sistema solar esté funcionando y dándote los resultados que mereces.",
    icon: ComputerDesktopIcon,
  },
  {
    name: "Trámites ante CFE",
    description:
      "Desde el papeleo hasta los permisos, nos encargaremos de todos los todos los aspectos para la instalación de tu sistema.",
    icon: LockClosedIcon,
  },
  {
    name: "Mantenimiento",
    description:
      "Servicio preventivo y correctivo para asegurarnos que el equipo siempre funcione al 100%. Además, contamos con seguro contra daños de hasta $1,000,000 USD.",
    icon: CogIcon,
  },
];

function parseCustomerType(solution: string): customerType {
  switch (solution) {
    case "commercial":
    case "industrial":
      return solution;
    default:
      return "residential";
  }
}

type PricerSelectorProps = {
  response: WebleadPreviewResponse;
  onSetPanels: (x: number) => Promise<void>;
  openModal: () => void;
};

const PanelSelector = ({
  response,
  onSetPanels,
  openModal,
}: PricerSelectorProps) => {
  const { numPanels, annualKwhConsumption, systemSizeWatts } = response;

  const [loading, setLoading] = useState(false);

  const onRemovePanel = useCallback(() => {
    if (numPanels > 3) {
      setLoading(true);
      const unsetLoading = () => {
        setLoading(false);
      };
      onSetPanels(numPanels - 1).then(unsetLoading, unsetLoading);
    }
  }, [onSetPanels, numPanels]);

  const onAddPanel = useCallback(() => {
    setLoading(true);
    const unsetLoading = () => {
      setLoading(false);
    };
    onSetPanels(numPanels + 1).then(unsetLoading, unsetLoading);
  }, [onSetPanels, numPanels]);

  const size = (systemSizeWatts / 1000.0).toFixed(2);

  const solarRatioMin = (
    (response.minAnnualKwhProduction / response.annualKwhConsumption) *
    100
  ).toFixed(0);

  const solarRatioMax = (
    (response.maxAnnualKwhProduction / response.annualKwhConsumption) *
    100
  ).toFixed(0);

  let solarRatioTxt = `${solarRatioMin}% - ${solarRatioMax}%`;

  if (solarRatioMin === solarRatioMax) {
    solarRatioTxt = `${solarRatioMin}%`;
  }

  const changeButton = (
    <button type="button" className="underline" onClick={openModal}>
      cambiar
    </button>
  );

  return (
    <div className="py-16 self-stretch">
      <Container column>
        <SectionHeader>Tu instalación solar estimada</SectionHeader>
        <SectionDescription>
          Basándonos en lo que pagas de luz, estimamos que estás consumiendo{" "}
          {annualKwhConsumption.toFixed(0)} kWh al año ({changeButton})
          <br />
          Tu sistema fotovoltaico ideal consiste de:
        </SectionDescription>
        <div className="grid mt-8 grid-cols-1 md:grid-cols-3 border border-gray-900 rounded-lg">
          <div className="flex flex-col gap-4 p-6">
            <span>Consumo cubierto por energía solar</span>
            <span className="bold text-3xl">{solarRatioTxt}</span>
          </div>
          <div className="flex flex-col gap-4 p-6 border-gray-900 border-y md:border-y-0 md:border-x">
            <span>Número de panales solares</span>
            <div className="flex flex-row items-center">
              <Button plain onClick={onRemovePanel}>
                <ChevronLeftIcon className="w-6 h-6" />
              </Button>
              {!loading ? (
                <span className="bold text-3xl flex-1">{numPanels}</span>
              ) : (
                <Loading className="flex flex-1 justify-center" />
              )}
              <Button plain onClick={onAddPanel}>
                <ChevronRightIcon className="w-6 h-6" />
              </Button>
            </div>
          </div>
          <div className="flex p-6 flex-col gap-4">
            <span>Tamaño del sistema</span>
            <span className="bold text-3xl">{size} kW</span>
          </div>
        </div>
      </Container>
    </div>
  );
};

type PricePreviewerProps = {
  response: WebleadPreviewResponse;
  solution: string;
  canReserveOnline: boolean;
  onSetPanels: (x: number) => Promise<void>;
  openModal: () => void;
  data: any;
};

const PricePreviewer = ({
  solution,
  response,
  canReserveOnline,
  onSetPanels,
  openModal,
  data,
}: PricePreviewerProps) => {
  const { customerT1Residential } = response;
  const isDac = !customerT1Residential && solution === "residential";
  const isPDBT = solution === "commercial";
  const annualDacPrice =
    parseInt(
      response.t1ResidentialInfo?.minimumCfeBillDacMxn?.replace(/,/g, ""),
      10,
    ) * 6;

  return (
    <>
      {customerT1Residential && (
        <div className="w-full">
          <DacIsBadSection
            t1Price={response.t1ResidentialInfo?.currentCfeBillMxn}
            dacPrice={response.t1ResidentialInfo?.minimumCfeBillDacMxn}
          />
          <ConsumptionsToBeDac
            response={response}
            annualDacPrice={annualDacPrice}
          />
        </div>
      )}
      {isDac && (
        <div className="w-full">
          <YouAreInDacSection />
          <HowToGetOutofDac annualDacPrice={annualDacPrice} />
        </div>
      )}
      {(isDac || customerT1Residential) && (
        <div className="w-full">
          <WelcomeComfort />
          <PanelSelector
            onSetPanels={onSetPanels}
            response={response}
            openModal={openModal}
          />
          <FinancingSection
            response={response}
            canReserveOnline={canReserveOnline}
          />
        </div>
      )}

      {isPDBT && (
        <div className="w-full">
          <PdbtIntro />
          <PdbtTestimonials data={data} />
          <PanelSelector
            onSetPanels={onSetPanels}
            response={response}
            openModal={openModal}
          />
          <LeaseVsBuy canReserveOnline={canReserveOnline} />
        </div>
      )}
    </>
  );
};

const SuccessPage = ({ data }) => {
  const seoProps = useSeoProps(
    "success",
    "Success",
    "Conoce las empresas con las que tenemos alianzas y consulta las promociones disponibles",
  );

  const [
    {
      force,
      // feature flag hint. Since the success page needs to be a full page reload
      // (thanks facebook). We have a small delay for showing these feature flags
      // However, by specifying a hint - we can keep the UI jank to a minimum.
      ffHint,
      solution,
      city,
      state,
      lastBillMxn,
      modal,
      installModal,
      firstName,
    },
    setSp,
  ] = useSearchParams();
  // If solution is not specified, assume residential
  const isResidential = !solution || solution === "residential";
  const isCommercial = solution === "commercial";
  const isIndustrial = !isResidential && !isCommercial;
  let experimentVariant = useBrightFeatureFlag("interactive_proposal_7");
  const getWebleadPreviewPayloadCall = useUiFriendlyWebleadPreviewParams();

  // We are going to trust the URL feature flag more than the
  // `useBrightFeatureFlag` because on this page, we are almost certainly
  // "identify-ing" concurrently. So, there is a little bit of time where
  // posthog is figuring out which feature flag belongs to this user (they are
  // merging the anon id and the new id) Posthog always figures it out for us at
  // the end (it has the correct value), so our analytics are valid - however we
  // need to show these customers the right thing.
  if (ffHint) {
    experimentVariant = ffHint as any;
  }

  // Try to identify posthog's feature-flag re-association failures so we can track them
  useEffect(() => {
    if (ffHint && experimentVariant && ffHint !== experimentVariant) {
      capture("posthog-flag-persist-across-auth-failed", {
        origValue: ffHint,
        newValue: experimentVariant,
      });
    }
  }, [ffHint, experimentVariant]);

  const inExperimentGroup =
    interactiveProposalGroupVariants.includes(experimentVariant);

  const canReserveOnline = [
    "interactive_high_price_new_flow",
    "interactive_low_price",
  ].includes(experimentVariant);

  const reactIsLoading = useReactLoading();

  // This is a query param that allows us to test this "on prod"
  const showPricer = (force === "true" || inExperimentGroup) && isResidential;

  const customerType = parseCustomerType(solution);
  const closeModal = useCallback(() => {
    setSp({ modal: null });
  }, [setSp]);

  const openModal = useCallback(() => {
    setSp({ modal: "open" });
  }, [setSp]);

  const closeInstallationModal = useCallback(() => {
    setSp({ installModal: null });
  }, [setSp]);

  const [response, setResponse] = useState<WebleadPreviewResponse | null>(null);

  const startWhatsappWelcomeFlow = useCallback(
    async (webleadPreviewResponse: WebleadPreviewResponse) => {
      sessionStorage.setItem("welcomeFlowStarted", "true");
      const apiUrl =
        process.env.NODE_ENV === "production"
          ? "https://api.thinkbright.mx/leads/startWhatsappWelcomeFlow"
          : "http://localhost:8089/leads/startWhatsappWelcomeFlow";

      const { systemSizeWatts, numPanels } = webleadPreviewResponse;
      const systemSizeKW = (systemSizeWatts / 1000.0).toFixed(2);
      const { monthlyFee, annualEscalator } = webleadPreviewResponse.operating;
      const minMonthlyFee = (parseFloat(monthlyFee) * 0.9).toFixed(0);
      const escalator = ((annualEscalator - 1.0) * 100).toFixed(2);
      const panelWatts = systemSizeWatts / numPanels;
      const interactiveProposalQuery =
        "success" + window.location.href.split("success")[1];

      const payload = {
        token: sessionStorage.getItem("token"),
        meta: {
          minMonthlyFee,
          maxMonthlyFee: monthlyFee,
          systemSizeKW,
          panelCount: numPanels.toString(),
          panelWatts: panelWatts.toString(),
          escalator,
          interactiveProposalQuery,
        },
      };

      await apiRequestToOps("POST", apiUrl, payload);
    },
    [],
  );

  const onSetPanels = useCallback(
    async (panels?: number) => {
      const payload = getWebleadPreviewPayloadCall({
        lastBillMxn,
        city,
        panels,
        variant: experimentVariant,
        customerType,
        riskCategory: "high_risk",
      });

      const { body } = await webleadPreview(payload);
      capture("interactive_proposal_viewed", {
        system_size_watts: body.systemSizeWatts,
        annual_kwh_consumption: body.annualKwhConsumption,
        panels: body.numPanels,
        is_custom_system_size: Boolean(panels),
        city,
        last_bill_mxn: lastBillMxn,
        total_savings_percent: parseFloat(body.financial.totalSavingsPercent),
        $set: {
          latest_annual_kwh_consumption_estimate: body.annualKwhConsumption,
          city: city,
        },
        $set_once: {
          initial_annual_kwh_consumption_estimate: body.annualKwhConsumption,
          initial_last_bill_mxn_estimate: lastBillMxn,
          first_total_savings_percent: parseFloat(
            body.financial.totalSavingsPercent,
          ),
        },
      });

      setResponse(body);
      ScrollToTop();
    },
    [
      getWebleadPreviewPayloadCall,
      experimentVariant,
      setResponse,
      lastBillMxn,
      city,
    ],
  );

  useEffect(() => {
    if (inExperimentGroup) {
      onSetPanels();
    }
    ScrollToTop();
  }, [onSetPanels, inExperimentGroup]);

  useEffect(() => {
    if (response && !sessionStorage.getItem("welcomeFlowStarted")) {
      startWhatsappWelcomeFlow(response);
    }
  }, [response, startWhatsappWelcomeFlow]);

  const formOnSuccess = useCallback(
    async (result: WebleadPreviewResponse, values: PricerFormValues) => {
      setResponse(result);

      setSp({
        city: values.city,
        state: values.state,
        lastBillMxn: values.lastBillMxn,
        // close the modal too
        modal: null,
      });
    },
    [setSp, setResponse],
  );

  let welcomeText;
  if (isResidential) {
    welcomeText = `¡${upperFirst(firstName)}, tu propuesta de energía solar está
  lista!`;
  } else if (isCommercial) {
    welcomeText = `¡${upperFirst(
      firstName,
    )}, bienvenido a la solución energérica
    que tu negocio necesita!`;
  }

  if (inExperimentGroup && !response) {
    return <LoadingSection />;
  }

  return (
    <div className="px-2 md:px-0">
      <Layout>
        <SEO {...seoProps} />
        <Modal
          title="Estimador de Costos"
          open={Boolean(modal)}
          closeModal={closeModal}
        >
          <PricerForm
            customerType={customerType}
            city={city}
            state={state}
            lastBillMxn={lastBillMxn}
            onSuccess={formOnSuccess}
          />
        </Modal>
        <Modal
          title=""
          open={Boolean(installModal)}
          closeModal={closeInstallationModal}
        >
          <ScheduleInstallationForm
            previewResp={response}
            closeModal={closeInstallationModal}
          />
        </Modal>
        <div className="flex flex-col justify-center items-center h-full text-center">
          {inExperimentGroup && !isIndustrial && response && (
            <div className="max-w-xl flex-col items-center h-full md:h-auto pb-4">
              <h1 className="text-2xl font-bold md:text-left text-center px-4 py-5">
                {welcomeText}
              </h1>
              <StaticImage
                alt="Techo de una casa con paneles solares"
                height={450}
                src="../images/illustrations/solar_panel_roof.jpg"
              />
              <p className="text-md text-left py-4">
                *Esta propuesta es preliminar
              </p>
            </div>
          )}
          {!!response && (
            <PricePreviewer
              solution={solution}
              response={response}
              canReserveOnline={canReserveOnline}
              onSetPanels={onSetPanels}
              openModal={openModal}
              data={data}
            />
          )}

          {!inExperimentGroup && (
            <Container column className="py-16">
              <div className="flex self-center items-center justify-center h-20 w-20 rounded-lg bg-green-500">
                {reactIsLoading && (
                  <Loading className="text-white center animate-pulse" />
                )}
                {!reactIsLoading && (
                  <CheckIcon className="h-14 w-14 text-white" />
                )}
              </div>
              <SectionHeader>
                <div className="pt-5">
                  ¡Nos pondremos en contacto contigo pronto!
                </div>
              </SectionHeader>
              <SectionDescription>
                <div className="pb-5">
                  {showPricer
                    ? "¿No quieres esperar? Puedes revisar una propuesta preliminar en este momento."
                    : "En lo que recibes nuestra llamada, tenemos información importante para ti."}
                </div>
              </SectionDescription>
              {showPricer && !response && (
                <div className="flex self-center items-center justify-center">
                  <Button size="large" shadow onClick={openModal}>
                    Obtener propuesta preliminar
                  </Button>
                </div>
              )}
            </Container>
          )}

          {((inExperimentGroup && response) || !inExperimentGroup) && (
            <Container column className="py-16">
              <SectionHeader>
                Estamos listos para ayudarte en tu transición a Energía Solar
              </SectionHeader>
              <SectionDescription>
                No olvides tener un recibo de luz a la mano para poder obtener
                una cotización 100% personalizada
              </SectionDescription>
              <SectionHeader>¿Qué dicen nuestros clientes?</SectionHeader>
              <Testimonials />
            </Container>
          )}
        </div>
      </Layout>
    </div>
  );
};

export default SuccessPage;

const LoadingSection = () => {
  return (
    <div className="flex flex-col justify-center items-center h-10 pt-16 pb-48">
      <div className="max-w-xl flex flex-col items-center h-full md:h-auto pb-4">
        <h1 className="text-2xl font-bold text-center py-5">
          Estamos generando tu propuesta
        </h1>
      </div>
      <div className="mt-16 md:mt-0">
        <Loading className="text-white animate-pulse" />
      </div>
    </div>
  );
};

export const query = graphql`
  fragment testimonalImgs on File {
    childImageSharp {
      gatsbyImageData(height: 450)
    }
  }

  query {
    pdbttestimonial1: file(relativePath: { eq: "abarrotes-mony.jpg" }) {
      ...testimonalImgs
    }
    pdbttestimonial2: file(relativePath: { eq: "funerales-lozano.jpg" }) {
      ...testimonalImgs
    }
    pdbttestimonial3: file(relativePath: { eq: "medical-scanner.jpg" }) {
      ...testimonalImgs
    }
    pdbttestimonial4: file(relativePath: { eq: "pizzeria-pizza-burger.jpg" }) {
      ...testimonalImgs
    }
    pdbttestimonial5: file(relativePath: { eq: "qualitas.jpg" }) {
      ...testimonalImgs
    }
    pdbttestimonial6: file(
      relativePath: { eq: "residencial-palma-grand.jpg" }
    ) {
      ...testimonalImgs
    }
  }
`;
