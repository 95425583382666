import { getImage, GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Container from "./Container";

const SectionHeader = ({ children }) => {
  return <h2 className="text-3xl font-bold">{children}</h2>;
};

const SectionDescription = ({ children }) => {
  return <p className="text-lg font-base mt-4 text-gray-500">{children}</p>;
};

const PdbtTestimonials = ({ data }) => {
  const pdbtTestimonials = [
    {
      photo: getImage(data.pdbttestimonial1.childImageSharp),
      name: "Abarrotes 'Mony'",
      city: "Morelos",
      panels: 18,
    },
    {
      photo: getImage(data.pdbttestimonial2.childImageSharp),
      name: "Funerales Lozano",
      city: "Ciudad de México",
      panels: 11,
    },
    {
      photo: getImage(data.pdbttestimonial3.childImageSharp),
      name: "Medical Scanner SA de CV",
      city: "Tamaulipas",
      panels: 51,
    },
    {
      photo: getImage(data.pdbttestimonial4.childImageSharp),
      name: "Pizzería 'Pizza Burger'",
      city: "Querétaro",
      panels: 16,
    },
    {
      photo: getImage(data.pdbttestimonial5.childImageSharp),
      name: "Quálitas Echegaray",
      city: "Estado de México",
      panels: 33,
    },
    {
      photo: getImage(data.pdbttestimonial6.childImageSharp),
      name: "Residencial Palma Grand",
      city: "Quintana Roo",
      panels: 17,
    },
  ];

  return (
    <div className="bg-white py-16 self-stretch">
      <Container column>
        <SectionHeader>Ya funciona, otros lo han logrado</SectionHeader>
        <SectionDescription>
          No estás solo en esta decisión. Empresas como la tuya ya han dado el
          paso y están viendo los beneficios.
        </SectionDescription>

        <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-4">
          {pdbtTestimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-md overflow-hidden"
            >
              <GatsbyImage
                image={testimonial.photo}
                alt={`Instalación de ${testimonial.name}`}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-xl font-semibold">{testimonial.name}</h3>
                <p className="text-gray-600">Ciudad: {testimonial.city}</p>
                <p className="text-gray-600">
                  Número de paneles: {testimonial.panels}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Container>
    </div>
  );
};

export default PdbtTestimonials;
